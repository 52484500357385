import styled from 'styled-components'
import { gray, white } from 'src/styles/colors'

export const Section = styled.div`
  h1, h2 {
    font-family: "Sora";
  }

  .bread-crumb {
    span {
      color: ${gray['800']} !important;

      a {
        color: ${gray['800']};
        font-weight: 900;
      }
    }
  }

  .gatsby-image-wrapper {
    max-width: 456px;
    margin: 0 auto;
    margin-right: 0;
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`
